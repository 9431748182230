import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import logo from '../../../assets/img/logo6.png'
import logo2 from '../../../assets/img/login2.png'
import { RootState } from '../../../reducers'
import { useRouter } from '../../../shared/utils/hooks/useRouter'
import { AppDispatch } from '../../../store'
import { ToastError, ToastSuccess } from '../../shared/toast/Toast'
import { ILoginForm, login, register } from './auth.api'
import { fetching } from './auth.reducer'
import { NavLink } from 'react-router-dom'
import userLogin from '../../../assets/img/user-login.png'
import passLogin from '../../../assets/img/pass-login.png'
import repassLogin from '../../../assets/img/repass-login.png'
import maLogin from '../../../assets/img/ma-login.png'
import hiddenPass from '../../../assets/img/hiden-pass.png'
import showPass from '../../../assets/img/show-pass.png'
interface ILocationPath {
  path?: string
}

const RegsiterWap = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { navigate, location } = useRouter()
  const state = location.state as ILocationPath
  const { user, loading, loginSuccess, firstTimeLogin } = useSelector(
    (state: RootState) => state.authentication,
  )

  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required('Không được để trống'),
    password: Yup.string().required('Không được để trống'),
    repassword: Yup.string().required('Không được để trống'),
    agentId: Yup.string().required('Không được để trống'),
  })

  useEffect(() => {
    if (user) {
      const redirectPath = state?.path || '/'
      navigate(redirectPath, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (loginSuccess && !firstTimeLogin) {
      ToastSuccess('Đăng nhập thành công')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess])

  const initialValues: ILoginForm = {
    username: '',
    password: '',
    repassword: '',
    agentId: '',
  }
  const [showPassWord, setShowPassWord] = useState<boolean>(false)
  const [showRePassWord, setShowRePassWord] = useState<boolean>(false)

  return (
    <CRow className="login-wap-fe m-0 min-vh-100 d-block">
      <div className="login_box-wrap d-flex justify-content-center align-items-center">
        <div className="logo-login">
          <img src={logo} height={100} alt="vtrack_logo" />
        </div>
      </div>
      <CCol className="box-auto">
        <CRow className="align-items-center">
          <CCol xs={12} className="px-3 mt-3 text-center">
            <h1 style={{ fontSize: 30, fontWeight: 500 }}>Hội viên đăng ký</h1>
            {/* <img src={logo} height={100} alt="vtrack_logo" /> */}
          </CCol>
          <CCol xs={12} className="px-3">
            <CCard
              className="login-card pt-4"
              style={{ background: 'transparent' }}
            >
              {/* <p className="text-display-bold-sm text-gray-modern-900 mb-5">Đăng nhập</p> */}
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  if (values.password !== values.repassword) {
                    ToastError('Mật khẩu không trùng khớp')
                    return
                  }

                  dispatch(fetching())
                  dispatch(register(values))
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <CForm onSubmit={handleSubmit} className="custom-form">
                    <CRow className="mb-4">
                      <CCol xs={12}>
                        <div className="custom-form-input">
                          <CFormLabel>Tài khoản hội viên</CFormLabel>
                          <div className="d-flex gap-3 w-100">
                            <img
                              className="ms-4"
                              width={27}
                              height={27}
                              src={userLogin}
                            />

                            <CFormInput
                              value={values.username}
                              onChange={(
                                e: React.FormEvent<HTMLInputElement>,
                              ) => {
                                setFieldValue(
                                  'username',
                                  e.currentTarget.value.trim().toLowerCase(),
                                )
                              }}
                              type="text"
                              id="username"
                              name="username"
                              autoComplete="none"
                              placeholder="Vui lòng nhập tên tài khoản"
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        {/* <CFormFeedback
                          invalid
                          className={
                            !!errors.username && touched.username
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.username}
                        </CFormFeedback> */}
                      </CCol>
                    </CRow>

                    <CRow className="mb-4">
                      <CCol xs={12}>
                        <div className="custom-form-input">
                          <CFormLabel>Mật khẩu thành viên</CFormLabel>
                          <div className="d-flex gap-3 w-100 align-items-center">
                            <img
                              className="ms-4"
                              width={27}
                              height={27}
                              src={passLogin}
                            />
                            <CFormInput
                              value={values.password}
                              onChange={handleChange}
                              type={showPassWord ? 'text' : 'password'}
                              id="password"
                              name="password"
                              autoComplete="none"
                              placeholder="Vui lòng nhập mật khẩu"
                              onBlur={handleBlur}
                            />

                            {!showPassWord && (
                              <img
                                className="me-3"
                                width={25}
                                height={10}
                                src={hiddenPass}
                                onClick={() => setShowPassWord(true)}
                              />
                            )}

                            {showPassWord && (
                              <img
                                className="me-3"
                                width={22}
                                height={16}
                                src={showPass}
                                onClick={() => setShowPassWord(false)}
                              />
                            )}
                          </div>
                        </div>
                        {/* <CFormFeedback
                          invalid
                          className={
                            !!errors.password && touched.password
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.password}
                        </CFormFeedback> */}
                      </CCol>
                    </CRow>

                    <CRow className="mb-4">
                      <CCol xs={12}>
                        <div className="custom-form-input">
                          <CFormLabel>Xác nhận mật khẩu</CFormLabel>
                          <div className="d-flex gap-3 w-100 align-items-center">
                            <img
                              className="ms-4"
                              width={27}
                              height={27}
                              src={repassLogin}
                            />
                            <CFormInput
                              value={values.repassword}
                              onChange={handleChange}
                              type={showRePassWord ? 'text' : 'password'}
                              id="repassword"
                              name="repassword"
                              autoComplete="none"
                              placeholder="Vui lòng xác nhận mật khẩu"
                              onBlur={handleBlur}
                            />

                            {!showRePassWord && (
                              <img
                                className="me-3"
                                width={25}
                                height={10}
                                src={hiddenPass}
                                onClick={() => setShowRePassWord(true)}
                              />
                            )}

                            {showRePassWord && (
                              <img
                                className="me-3"
                                width={22}
                                height={16}
                                src={showPass}
                                onClick={() => setShowRePassWord(false)}
                              />
                            )}
                          </div>
                        </div>
                        {/* <CFormFeedback
                          invalid
                          className={
                            !!errors.repassword && touched.repassword
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.password}
                        </CFormFeedback> */}
                      </CCol>
                    </CRow>

                    <CRow className="mb-3">
                      <CCol xs={12}>
                        <div className="custom-form-input">
                          <CFormLabel>Mã giới thiệu</CFormLabel>
                          <div className="d-flex gap-3 w-100">
                            <img
                              className="ms-4"
                              width={25}
                              height={25}
                              src={maLogin}
                            />
                            <CFormInput
                              value={values.agentId}
                              onChange={handleChange}
                              type="text"
                              id="agentId"
                              name="agentId"
                              autoComplete="none"
                              placeholder="Nhập mã giới thiệu"
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        {/* <CFormFeedback
                          invalid
                          className={
                            !!errors.agentId && touched.agentId
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.agentId}
                        </CFormFeedback> */}
                      </CCol>
                    </CRow>

                    <CRow className="m-0 justify-content-end">
                      <CButton
                        className="btn-custom primary-500 mt-3 w-100 btn-custom-login"
                        type="submit"
                        disabled={loading}
                      >
                        Đăng ký
                      </CButton>
                    </CRow>

                    <CRow className="mt-3 justify-content-end text-more">
                      <p className="text-dark text-center">
                        Đã có tài khoản ?
                        <NavLink
                          to="/wap/login"
                          className={'fw-bold'}
                          style={{ color: '#8921ae' }}
                        >
                          {' '}
                          Quay lại đăng nhập
                        </NavLink>
                      </p>
                    </CRow>
                  </CForm>
                )}
              </Formik>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  )
}

export default RegsiterWap
