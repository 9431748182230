import { CCarousel, CCarouselItem, CCol, CImage, CRow } from '@coreui/react-pro'
import Layout from './Layout'
import banner1 from '../../../assets/img/b-1.jpg'
import banner2 from '../../../assets/img/b-2.jpg'
import banner3 from '../../../assets/img/b-3.jpg'
import banner4 from '../../../assets/img/b-4.jpg'
import banner5 from '../../../assets/img/b-5.jpg'
import banner6 from '../../../assets/img/b-6.jpg'
import banner7 from '../../../assets/img/b-7.jpg'
import banner8 from '../../../assets/img/b-8.jpg'

import tab1 from '../../../assets/img/tab1.png'
import game1 from '../../../assets/img/game1.png'

import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { formatVND } from '@/shared/utils/ultils'
import { useEffect, useState } from 'react'
import logo from '../../../assets/img/logo1.png'
import ClockIcon from '@/components/shared/icons/ClockIcon'
import logoWeb from '../../../assets/img/logo6.png'
import axios from '../../../shared/config/axios-interceptor'
import { IGameType } from '@/shared/model/gameType.model'
import Marquee from "react-fast-marquee";

const HomeWap = () => {
  const { user } = useSelector((state: RootState) => state.authentication)

  const [tab, setTab] = useState(0)
  const [gameType, setGameType] = useState<IGameType[]>([])
  const [config, setConfig] = useState('')

  const getGame = async () => {
    try {
      const response = await axios.get(
        '/game-type?limit=100&status=ACTIVE&sortBy=order&sortOrder=ASC&statusGame=ACTIVE',
      )
      setGameType(response.data.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getConfig = async () => {
    try {
      const response = await axios.get(
        '/config/2',
      )
      setConfig(response?.data?.content)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getConfig()
    getGame()
  }, [])

  return (
    <div className="home-wap">
      <Layout>
        <div className="home_top">
          <div className="home_top-left">
            <div className="home_top-logo soc">
              <img height={30} src={logoWeb} alt="logo" />
            </div>
          </div>
          <div className="home_top-right">
            <div className="home_top-kf icon-kf">
              <NavLink to={'/history-order'}>
                <ClockIcon color="#fff" width={20} height={20} />
              </NavLink>
            </div>
          </div>
        </div>

        <div className="main">
          <div className="banner-slide">
            <CCarousel controls interval={2000}>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner1} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner2} alt="slide 2" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner3} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner4} alt="slide 4" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner5} alt="slide 5" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner6} alt="slide 6" />
              </CCarouselItem>

              <CCarouselItem>
                <CImage className="d-block w-100" src={banner7} alt="slide 7" />
              </CCarouselItem>

              <CCarouselItem>
                <CImage className="d-block w-100" src={banner8} alt="slide 8" />
              </CCarouselItem>
            </CCarousel>
          </div>

          <div className="box-info my-3 p-2">
            <div className="border-bottom  pb-2 mb-2 d-flex justify-content-between  align-items-center">
              <div className="d-flex align-items-center gap-2">
                <CImage
                  width={50}
                  height={50}
                  className="d-block avatar"
                  src={banner1}
                  alt="slide 1"
                />
                <div>
                  <div style={{ fontSize: 13 }}>
                    Biệt danh: {user?.username}
                  </div>
                  <div style={{ fontSize: 13 }}>
                    Số dư: {formatVND(user?.money ?? 0)}
                  </div>
                </div>
              </div>

              <div className="d-flex gap-2">
                <NavLink
                  to={'/message'}
                  style={{ fontSize: 13, background: '#f08a7b', color: '#fff' }}
                  className="btn"
                >
                  Nạp tiền
                </NavLink>
                <NavLink
                  to={'/withdraw'}
                  style={{ fontSize: 13, background: '#c87ac8', color: '#fff' }}
                  className="btn"
                >
                  Rút tiền
                </NavLink>
              </div>
            </div>

            <div className="d-flex gap-2 pb-1 align-items-center">
              <img
                width={20}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAdCAYAAADLnm6HAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASTSURBVHgB5Vc9bFtVFD7nXhsTWglngTLZRWJgwpXYcVgYGBxLqFJEUewMQArIZWFgqJMRFlokFP6q14qWDAicDmW1uyPFmRgYbH7EhBQPJbSO3z2cc+99f8VOk3ZAVU8cv/fuz/n5znfPeQZ41AXhAeWPS9caStELCPriM82lIRxRcnCf8vtXm1VUECBSWZ4JQ7m8D0eUIzsw2Ngso84FBOYlIIOKPQAi1AhFuA85tAODoFOc7E3OEWCLDRc5eYTyxRfFieRxmrX3n05QpnC/qwBGBZ1fwHpzFM2pwxj/+dPvG+EeDTjaNpsqCnGIo2bjcsNPSOoANt1mw8D5IkWVfRh303Pxtp8++nFRI9YQQlDagGLXGFZAbcpIpqo0h4oMuSJQSCRXAIOar2jHIDjReH1llhO7mxvluYLqst4S27iYXzxr+YLbbYa2MNdVmiqsjI3wB8UBMcIQsVFJsxiUca1iRzjqeK0EGJxYThzYvRZUQN0ezS+tDqOxW53PKnnIbStl2PfJQr7+Xk+Fjx/7hHGoODQpAZLAZhb8lx1gTyTnyRI/iZDJ/1/fBos8tq2w0N0Ngpicx+vv9HnpBbtFq7ZclSGq2QFESJcFG7Zojp2KSUaxWVHg12HKeX0Henzp83859wS00s7lobAuAPGOKt34uiQoOlLBdBKLYxEUYE3ZT+IB/LeazTeZ5aGvCRS20ijYE0B406F2p8HZIKtNYnBRR7YoBjZGAWMk0LLfAuT2330M599o9nigxxHMF46Fy+k5ovA6yQYDFWUjtKZYkTWUpoHxhi0K7uiRP/5M/cgx4oKEKW7E0Src8jypZCYey/etTTIVlaCIlMEyyqy/F2OIHiUxnCbsjJZiKPzVe1ZKj+fH4UgQ4KNUVl6/fGOc60inCzu+dxGTRcRWQu8QzqqCRltDMENEtXXAU4qSxTG90PEiGXXmbRFEm0fn1HQjKrTkM1Om3AYaqYT/sRJysIluQRs8MhK1r77+L43YtF6gSNccSXAnPT7h4+l6iRrIMaQIDkdGF5WzaK1TdA+WB862v7nH+wRVZSeX+K2MY1zyxYYhs6N4wU6EiWN5EpM/begwdlOpnFKqOGWrKEgpvrTG82VeP5w7vXIz4xbSoujmCr7FPYTWkyl7JAEy4LqaQI6kyZjLAILnSLob7n4T8MuKPi+LQjNeSBsfdzYarL/ESodYe/e6evHDV7a4vzRZzTCp/VHKkVxa7DFwVPXlCf0pcNy5KxMT6LPtHqA5l25G1AmK7Hdb6gY/rvlQE9n++EZF5/DJnNcirysYhie5Fbe5W9q6jVw7lZQM6ZDSCbUokS5Jl59unGnCAbL3w+cXcgpavG+Qq519VsYyb0SnPni1P2Wf5O/yLxudNkPa9jXR9q4IAUQguIf8/d0Xa7y6xdGP9glfjsYP9UYk8txqfX2MY/baXJFnokwjPvA03Op8WeGqd96SKFTNuXqSlkM7IPL86tLw5Nunm5z1U1znh5S8ERwox+tv9k1IK5y4auG1tzJHEuEB5Df+TaCZVMyDkkbTfGr5zBX4P+TPq1dL8LDKv0Z6IgldBIt7AAAAAElFTkSuQmCC"
              />
              <Marquee style={{ fontSize: 13 }}>
                {config}
              </Marquee>
            </div>
          </div>

          <div className="box-games d-flex gap-2">
            <div className="box-tab">
              <div className="item">
                <img
                  style={{ width: '100%' }}
                  src={tab1}
                  onClick={() => setTab(0)}
                />
              </div>

              {gameType.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <img
                      style={{ width: '100%' }}
                      src={item?.imageMobile ? item?.imageMobile : tab1}
                      onClick={() => setTab(item.id)}
                    />
                  </div>
                )
              })}
            </div>

            <div className="box-game">
              <div
                className="container-box-game"
                style={{ paddingBottom: 200 }}
              >
                {gameType.map((item: IGameType, index) => {
                  return (
                    (tab === 0 || tab === item.id) && (
                      <div className="content1 mb-4" key={index}>
                        <div className="row g-0">
                          {item?.game.map((it, idx) => {
                            return (
                              <div key={idx} className="col-6">
                                <NavLink to={`/game/${it.id}`}>
                                  <CImage
                                    style={{ width: '100%' }}
                                    src={
                                      it.imageMobile ? it.imageMobile : game1
                                    }
                                  />
                                </NavLink>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default HomeWap
