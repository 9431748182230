// export const SERVER_API_URL = 'http://localhost:8086/api/';
// export const UPLOAD_API_URL = 'http://localhost:8086/api/';
// export const SYNCFUSION_COMMUNITY_KEY =
//   'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=';
// export const SOCKET_URL = 'ws://localhost:8086';


// PROD

export const SERVER_API_URL = 'https://api.venetian86.com/api/';
export const UPLOAD_API_URL = 'https://api.venetian86.com/api/';
export const SYNCFUSION_COMMUNITY_KEY =
  'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=';
export const SOCKET_URL = 'https://api.venetian86.com';
