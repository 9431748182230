import Layout from "./Layout";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { CButton } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import { getProfile } from "../auth/auth.api";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { formatVND } from "@/shared/utils/ultils";
import { ResponseStatusUser } from "@/shared/enumeration/ResponseStatus";

const Withdraw = () => {
    const { location, navigate } = useRouter();

    const { user } = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch<AppDispatch>();

    const [money, setMoney] = useState(user?.money ?? 0);
    const [noBank, setNoBank] = useState(false);

    const [password, setPassword] = useState('');

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (Number.isNaN(Number(value))) return
        setMoney(Number(value));
    }

    useEffect(() => {
        if (!user?.cardName || !user?.cardNumber || !user?.bankName) {
            setNoBank(true)
        }
    }, [user?.cardName, user?.cardNumber, user?.bankName])

    const onSubmit = async () => {

        if (user?.status === ResponseStatusUser.CAMGIAODICH) {
            ToastError('Bạn bị cấm giao dịch. Vui lòng liên hệ CSKH để đặt cược');
            return;
        }


        if (money <= 0) {
            ToastError('Số tiền rút phải lớn hơn 0')
            return
        }

        if (money > (user?.money ?? 0)) {
            ToastError('Số tiền không hợp lệ')
            return
        }

        if (!password) {
            ToastError('Vui lòng nhập mật khẩu rút tiền')
            return
        }

        if (!user?.passsWithdraw) {
            ToastError('Vui lòng thiết lập mật khẩu rút tiền trước')
            return
        }

        if (password !== user?.passsWithdraw) {
            ToastError('Mật khẩu rút tiền sai')
            return
        }

        try {
            const response = await axios.post('/transaction', {
                money, type: 2
            });
            setMoney(0)
            ToastSuccess('Hệ thống đang xử lý. Vui lòng chờ trong ít phút')
            dispatch(getProfile());
        } catch (error) {
            console.error('Error:', error);
            dispatch(getProfile());
        }
    }


    if (noBank) {
        return <Navigate to="/add-bank" replace state={{ path: location.pathname }} />;
    }

    return (
        <Layout >
            <div className="box-withdraw box-withdraw-wap">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Rút tiền</h5>
                </div>

                <div className="text-center my-5">
                    <div style={{ fontSize: 30, color: "#00be6e" }}>{formatVND(user?.money ?? 0)}</div>
                    <div style={{ fontSize: 14, color: "#333" }}>Số dư hiện tại</div>
                </div>

                <div className="box-content mt-4">

                    <h5>Giới hạn rút: {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}</h5>

                    <div className="d-flex align-items-center form-withdraw gap-4">
                        <label>Số tiền rút</label>
                        <input className="amount" type="text" onChange={(e) => onChange(e)} placeholder="Nhập số tiền rút" value={money} />
                    </div>


                    <div className="d-flex align-items-center form-withdraw gap-4 mt-4">
                        <label>Mật khẩu rút tiền</label>
                        <input className="amount" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Nhập mật khẩu rút tiền" />
                    </div>

                    <div className="bank mt-5">
                        <div>Thông tin ngân hàng</div>
                        <div>{user?.bankName}</div>
                        <div>{user?.cardNumber}</div>
                        <div>{user?.cardName}</div>
                    </div>

                    <div className="mt-4">
                        <CButton className="w-100 submit" onClick={onSubmit}>Xác nhận</CButton>
                    </div>


                    <p style={{ fontSize: 14, color: "red" }} className="mt-4">Thông báo: Quý khách lưu ý thời gian rút tiền từ khung thời gian 9 giờ 30 phút đến 23 giờ 00</p>

                </div>

            </div>
        </Layout>
    )
}

export default Withdraw;