import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createEntity,
  getEntities,
  getEntity,
  updateEntity,
} from './configManagement.api'
import { CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import CustomFormikEditor from '@/components/shared/CustomEditor/CustomEditor'
import { Formik } from 'formik'
import { IConfig, INewConfig } from '@/shared/model/config.model'
import { fetching, resetEntity, selectEntityById } from './configManagement.reducer'
import { ToastSuccess } from '@/components/shared/toast/Toast'

const ConfigManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const { initialState } = useSelector(
    (state: RootState) => state.configReducer,
  )
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState

  useEffect(() => {
    if (updateEntitySuccess) {
      // ToastSuccess('Cập nhật thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    dispatch(getEntities(filterState))
  }, [dispatch, filterState])



  const config1 = useSelector(selectEntityById('1'))
  const config2 = useSelector(selectEntityById('2'))

  const initialValues: any = {
    // id: 0,
    // key: '',
    content1: config1?.content || '',
    content2: config2?.content || '',
  }

  // console.log(config || initialValues);

  if (!config1) return <></>

  return (
    <>
      <div className="tab-outlet-content">
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(value) => {
            // console.log(value)
            dispatch(fetching())
            // console.log(value);

            const numberOfProperties = Object.keys(value).length;

            for (let index = 0; index < numberOfProperties; index++) {
              const element = value['content' + (index + 1)];

              const data: any = new Object();
              data.content = element

              if (index == 0) {
                if (config1) {
                  data.id = index + 1
                  data.key = 'nottification-first-login'
                  dispatch(updateEntity(data as IConfig))
                } else {
                  data.key = 'nottification-first-login'
                  dispatch(createEntity(data))
                }
              }

              if (index == 1) {
                if (config2) {
                  data.id = index + 1
                  data.key = 'nottification'
                  dispatch(updateEntity(data as IConfig))
                } else {
                  data.key = 'nottification'
                  dispatch(createEntity(data))
                }
              }

            }

            ToastSuccess('Cập nhật thành công');

            // if (config) {
            //   dispatch(updateEntity(value as IConfig))
            // } else {
            //   value.key = 'nottification-first-login'
            //   dispatch(createEntity(value))
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit} className="custom-form">
              <CRow className='g-4'>
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content" style={{ fontSize: 16 }}>
                    Thông báo khẩn cấp
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CFormInput
                    size="sm"
                    name="content2"
                    value={values.content2}
                    onChange={handleChange}
                  />
                </CCol>

                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content" style={{ fontSize: 16 }}>
                    Cấu hình thông báo
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CustomFormikEditor
                    simpleMode
                    size="sm"
                    name="content1"
                    value={values.content1}
                    setFieldValue={setFieldValue}
                  />
                </CCol>

                <CCol xs="12">
                  <CButton type="submit" className="btn btn-primary">
                    Lưu cấu hình
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          )}
        </Formik>
      </div>
    </>
  )
}

export default ConfigManagement
