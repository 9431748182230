import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/store'
import Header from './Header'
import Sidebar from './Sidebar'
import { CCarousel, CCarouselItem, CImage } from '@coreui/react-pro'
import banner1 from '../../../assets/img/b-1.jpg'
import banner2 from '../../../assets/img/b-2.jpg'
import banner3 from '../../../assets/img/b-3.jpg'
import banner4 from '../../../assets/img/b-4.jpg'
import banner5 from '../../../assets/img/b-5.jpg'
import banner6 from '../../../assets/img/b-6.jpg'
import banner7 from '../../../assets/img/b-7.jpg'
import banner8 from '../../../assets/img/b-8.jpg'

import w1 from '../../../assets/img/w-1.png'
import w2 from '../../../assets/img/w-2.png'
import w3 from '../../../assets/img/w-3.png'
import w4 from '../../../assets/img/w-4.png'
import w5 from '../../../assets/img/w-5.png'
import w6 from '../../../assets/img/w-6.png'

import b1 from '../../../assets/img/b-1.png'
import b2 from '../../../assets/img/b-2.png'
import b3 from '../../../assets/img/b-3.png'
import b4 from '../../../assets/img/b-4.png'
import { NavLink } from 'react-router-dom'
import { historySelectors } from '../HistoryManagement/historyManagement.reducer'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import { RootState } from '@/reducers'
import { ResponseStatusDraw } from '@/shared/enumeration/ResponseStatus'
import { formatVND } from '@/shared/utils/ultils'
import AutoScrollLoop from './AutoScrollLoop'

const Home = () => {
  const dispatch = useDispatch<AppDispatch>()
  const historys = useSelector(historySelectors.selectAll)

  const [modal, setModal] = useState(false)

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer,
  )
  const { filterState } = initialState

  useEffect(() => {
    dispatch(
      getEntities({
        ...filterState,
        sortBy: 'historyId',
        sortOrder: 'DESC',
        status: ResponseStatusDraw.closed,
        isWin: 1,
      }),
    )
  }, [])

  const box1 = [
    {
      image: w1,
      title: 'PK10 1 phút',
      path: '/game/2',
    },
    {
      image: w2,
      title: 'PK10 2 phút',
      path: '/game/3',
    },
    {
      image: w3,
      title: 'PK10 3 phút',
      path: '/game/4',
    },
    {
      image: w4,
      title: 'PK10 5 phút',
      path: '/game/1',
    },
    {
      image: w5,
      title: 'PK10 10 phút',
      path: '/game/5',
    },
    {
      image: w6,
      title: 'PK10 20 phút',
      path: '/game/6',
    },
  ]

  const box2 = [
    {
      image: b1,
      title: 'Tencent Lotto',
      path: '/game/2',
    },
    {
      image: b2,
      title: 'Hà Nội 5 phút',
      path: '/game/1',
    },
    {
      image: b3,
      title: 'May mắn pk10',
      path: '/game/1',
    },
    {
      image: b4,
      title: 'Nhanh 3 phút',
      path: '/game/4',
    },
  ]

  return (
    <div className="container-web home-web">
      <Header />
      <div className="content-header"></div>
      <div className="page-body-web">
        <Sidebar />
        <div className="content-center">
          <div className="banner-top">
            <CCarousel controls interval={3000}>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner1} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner2} alt="slide 2" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner3} alt="slide 3" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner4} alt="slide 4" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner5} alt="slide 5" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner6} alt="slide 6" />
              </CCarouselItem>

              <CCarouselItem>
                <CImage className="d-block w-100" src={banner7} alt="slide 7" />
              </CCarouselItem>

              <CCarouselItem>
                <CImage className="d-block w-100" src={banner8} alt="slide 8" />
              </CCarouselItem>
            </CCarousel>
          </div>

          <div className="content-1">
            <div style={{ fontSize: 14, color: '#6a4e85' }}>HẠNG MỤC HOT</div>
            <div className="d-flex mt-3 gap-3 justify-content-between">
              {box1.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="d-flex flex-column justify-content-center align-items-center text-decoration-none"
                >
                  <img width={80} height={80} src={item.image} />
                  <p
                    className="mt-2 mb-2"
                    style={{ fontSize: 14, color: '#666' }}
                  >
                    {item.title}
                  </p>
                </NavLink>
              ))}
            </div>
          </div>

          <div className="content-1">
            <div>ĐỀ XUẤT</div>
            <div className="d-flex mt-3 gap-4">
              {box2.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="d-flex flex-column justify-content-center align-items-center text-decoration-none"
                >
                  <img className="w-100" src={item.image} />
                  <p
                    className="mt-2 mb-0"
                    style={{ fontSize: 14, color: '#666' }}
                  >
                    {item.title}
                  </p>
                </NavLink>
              ))}
            </div>
          </div>

          <div className="pb-5"></div>
        </div>
        <div className="content-right">
          <div className="item-right bxh">
            <div className="header-item px-3">BXH TRÚNG THƯỞNG</div>
            <div className="content-item list-bxh" style={{ height: 891 }}>
              <AutoScrollLoop historys={historys} />
              {/* <div className="list-bxh">
                {historys.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between pe-2 align-items-center"
                      style={{ height: 60 }}
                    >
                      <div style={{ fontSize: 13 }}>
                        <div>{item.biDatCuoc.game?.name}</div>
                        <div>
                          Mở thưởng kì trước{' '}
                          <span style={{ color: '#ee3047' }}>{item.KyId}</span>
                        </div>
                      </div>
                      <div style={{ fontSize: 13 }}>
                        Số tiền trúng thưởng:{' '}
                        <span style={{ color: '#ee3047' }}>
                          {formatVND(item?.addMoney ?? 0)}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
