import { SVGProps } from 'react';
const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 16}
    fill="none"
    stroke='currentColor'
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props?.fill}
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334Z"
        opacity={0.12}
      />
      <path
        stroke={props?.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M7.999 4v4l2.666 1.333m4-1.333A6.667 6.667 0 1 1 1.332 8a6.667 6.667 0 0 1 13.333 0Z"
      />
    </g>
  </svg>
);
export default ClockIcon;
