import Layout from './Layout'
import avatar from '../../../assets/img/avatar.gif'
import { NavLink } from 'react-router-dom'
import WithdrawalIcon from '@/components/shared/icons/web/WithdrawalIcon'
import ArrowNarrowLeftIcon from '@/components/shared/icons/ArrowNarrowLeftIcon'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import CardIcon from '@/components/shared/icons/web/CardIcon'
import MenuIcon1 from '@/components/shared/icons/web/MenuIcon1'
import MenuIcon2 from '@/components/shared/icons/web/MenuIcon2'
import MenuIcon3 from '@/components/shared/icons/web/MenuIcon3'
import ChangePassWordIcon from '@/components/shared/icons/web/ChangePassWordIcon'
import LogoutIcon from '@/components/shared/icons/header/LogoutIcon'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { logout, resetAll } from '../auth/auth.reducer'
import MenuIcon2_1 from '@/components/shared/icons/web/MenuIcon2_1'
import SModal from '@/components/shared/Modal/SModal'
import {
  CButton,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import { useState } from 'react'
import MenuIcon1_1 from '@/components/shared/icons/web/MenuIcon1_1'
import avatar1 from '../../../assets/img/avatar1.jpg'
import account1 from '../../../assets/img/account-1.png'
import account2 from '../../../assets/img/account-2.png'
import account3 from '../../../assets/img/account-3.png'
import account4 from '../../../assets/img/account-4.png'
import { formatVND } from '@/shared/utils/ultils'

const Account = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [tab, setTab] = useState(1)
  const [visible, setVisible] = useState(false)

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  return (
    <Layout>
      <div className="box-account-wap">
        <div className="header-top">
          <div className="top text-center">
            <img src={avatar1} className="avatar  mt-4 mb-2" />
            <div style={{ color: '#fff', fontSize: 14 }}>{user?.username}</div>
            <div style={{ color: '#fff', fontSize: 14 }}>
              Biệt danh: {user?.username}
            </div>
          </div>

          <div className="bottom d-flex justify-content-between align-items-center">
            <div style={{ color: '#fff', fontSize: 14 }} className="left">
              <div>
                <span style={{ color: '#fbe53a' }}>VIP{user?.vip ?? 1}</span>
              </div>
              <div>
                Nhận thưởng: <span style={{ color: '#fbe53a' }}>0</span>
              </div>
            </div>
            <div
              className="text-end right"
              style={{ color: '#fff', fontSize: 14 }}
            >
              Số dư:{' '}
              <span style={{ color: '#fbe53a' }}>
                {formatVND(user?.money ?? 0)}
              </span>
              <div className="mt-1 box-nap-rut d-flex justify-content-end gap-2">
                <NavLink className="text-decoration-none" to={'/message'}>
                  Nạp tiền
                </NavLink>
                <NavLink className="text-decoration-none" to={'/withdraw'}>
                  Rút tiền
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content-account">
          <div className="tab-header">
            <div
              className={
                tab == 1 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(1)}
            >
              <img src={account1} />
              <div>Tài khoản</div>
            </div>

            <div
              className={
                tab == 2 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(2)}
            >
              <img src={account2} />
              <div>Báo cáo</div>
            </div>

            <div
              className={
                tab == 3 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(3)}
            >
              <img src={account3} />
              <div>Đội ngũ</div>
            </div>

            <div
              className={
                tab == 4 ? 'tab-header-item active' : 'tab-header-item'
              }
              onClick={() => setTab(4)}
            >
              <img src={account4} />
              <div>Hệ thống</div>
            </div>
          </div>

          <div className="tab-content">
            {tab == 1 && (
              <div className="tab1">
                <NavLink to="/account-info">
                  <span>Thông tin cơ bản</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-withdraw">
                  <span>Lịch sử rút tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/message'}>
                  <span>Nạp tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/withdraw'}>
                  <span>Rút tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/mat-khau-rut-tien">
                  <span>Quản lý mật khẩu rút tiền</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
                <NavLink to="/history-withdraw">
                  <span>Tra cứu số dư</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 2 && (
              <div className="tab1">
                <NavLink to="/history-order">
                  <span>Lịch sử cược</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/history-order">
                  <span>Lịch sử nạp</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/history-order'}>
                  <span>Lịch sử rút</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 3 && (
              <div className="tab1">
                <NavLink to="/magioithieu">
                  <span>Mã giới thiệu</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}

            {tab == 4 && (
              <div className="tab1">
                <NavLink to="/uudai">
                  <span>Ưu đãi</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to="/uudai">
                  <span>Tin tức</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>

                <NavLink to={'/message'}>
                  <span>CSKH</span>
                  <ArrowRight color="#c7c7c7" width={12} />
                </NavLink>
              </div>
            )}
          </div>

          <div className="logout">
            <CButton onClick={onLogout}>Đăng xuất</CButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Account
