import { NavLink } from 'react-router-dom'
import home from '../../../assets/img/home.png'
import homeActive from '../../../assets/img/home-active.png'

import uuDai from '../../../assets/img/uu-dai.png'
import uuDaiActive from '../../../assets/img/uu-dai-active.png'

import cskh from '../../../assets/img/cksh.png'

import caNhan from '../../../assets/img/ca-nhan.png'
import caNhanActive from '../../../assets/img/ca-nhan-active.png'

const MenuBottom = () => {
  return (
    <div className="menu-bottom-wap d-flex justify-content-between align-items-center">
      <NavLink
        to={'/'}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img className='iat' width={28} src={home} />
        <img className='at' width={28} src={homeActive} />
        <span>Trang chủ</span>
      </NavLink>
      <NavLink
        to={'/uudai'}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img className='iat' width={28} src={uuDai} />
        <img className='at' width={28} src={uuDaiActive} />
        <span>Ưu đãi</span>
      </NavLink>
      <NavLink
        to={'/message'}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={30} height={27} src={cskh} />
        <span>CSKH</span>
      </NavLink>
      <NavLink
        to={'/account'}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img className='iat' width={27} src={caNhan} />
        <img className='at' width={27} src={caNhanActive} />
        <span>Cá nhân</span>
      </NavLink>
    </div>
  )
}

export default MenuBottom
