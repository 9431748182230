import { NavLink } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { CButton, CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import axios from '../../../shared/config/axios-interceptor'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import Nav from './Nav'
import Select, { SingleValue, components } from 'react-select'
import { ISelectValue } from '@/shared/shared-interfaces'
import { IBank } from '@/shared/model/bank.model'
import { bankSelectors } from '../BankManagement/banksManagement.reducer'
import { getEntities as getEntitiesBank } from '../BankManagement/banksManagement.api'
import { CustomOption, CustomSingleValue } from '../UserManagement/UserUpdate'

const TheNganHang = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [bankName, setBankName] = useState(user?.bankName || 0)
  const [cardNumber, setCardNumber] = useState<string>(user?.cardNumber || '')
  const [cardName, setCardName] = useState<string>(user?.cardName || '')

  const submitHanlder = async () => {
    if (bankName === 0 || cardNumber === '' || cardName === '') {
      ToastError('Vui lòng nhập đầy đủ thông tin')
      return
    }

    try {
      const response = await axios.put('/user/' + user?.id, {
        cardName,
        cardNumber,
        bankName,
      })
      ToastSuccess('Cập nhật ngân hàng thành công')
      dispatch(getProfile())
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const returnBankItem = (items: IBank[], selectBank?: IBank | null) => {
    return items.map((item) => {
      return {
        value: item.bankId,
        label: item.name,
        image: item.avatar,
      }
    })
  }

  useEffect(() => {
    dispatch(getEntitiesBank({ page: 1, limit: 100 }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const returnOption = (
    optionValue: number | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => Number(value) === Number(optionValue))
  }
  const banks = useSelector(bankSelectors.selectAll)

  const customBankItems: any = returnBankItem(banks)

  return (
    <div className="container-web page-rut-tien-web">
      <Header />
      <div className="content-header d-flex gap-3">
        <Nav />

        <div className="header-bottom m-0 w-100">
          <div
            className="top d-flex px-3  align-items-center fw-bold"
            style={{ height: 45 }}
          >
            Thẻ ngân hàng
          </div>

          <div className="p-3 d-flex gap-3">
            <div style={{ width: 900 }}>
              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>Tên ngân hàng</CFormLabel>
                </CCol>
                <CCol xs={9}>
                  {/* <CFormInput
                    value={bankName}
                    onChange={(e: any) => setBankName(e.target.value)}
                  /> */}

                  <Select
                    // menuIsOpen={true}
                    defaultValue={null}
                    className="custom-select form-height-44"
                    classNamePrefix="react-select"
                    value={
                      returnOption(
                        bankName as any,
                        customBankItems,
                      ) as any
                    }
                    onChange={(newValue: SingleValue<ISelectValue<string>>) => {
                      setBankName(Number(newValue?.value) || 0)
                    }}
                    noOptionsMessage={() => <>Không có ngân hàng nào</>}
                    id={'bankName'}
                    options={customBankItems}
                    placeholder={'Chọn Ngân Hàng'}
                    isClearable={true}
                    closeMenuOnSelect={true}
                    menuPosition={'absolute'}
                    name="bankName"
                    components={{
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>Số tài khoản</CFormLabel>
                </CCol>
                <CCol xs={9}>
                  <CFormInput
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>Chủ tài khoản</CFormLabel>
                </CCol>
                <CCol xs={9}>
                  <CFormInput
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                  />
                </CCol>
              </CRow>

              <div className="text-end mt-4 mb-3">
                <CButton
                  onClick={submitHanlder}
                  className="px-4"
                  style={{ background: '#f49400' }}
                  disabled={
                    (user?.bankName && user?.cardNumber && user?.cardName)
                      ? true
                      : false
                  }
                >
                  Lưu lại
                </CButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body-web">
        <Sidebar />
        {/* <div className='content-center'>
            dsad
        </div>
        <div className='content-right'>
        </div> */}
      </div>
    </div>
  )
}
export default TheNganHang
