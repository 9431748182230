import Layout from "./Layout";
import avatar from '../../../assets/img/avatar.gif';
import { NavLink, Navigate } from "react-router-dom";
import WithdrawalIcon from "@/components/shared/icons/web/WithdrawalIcon";
import ArrowNarrowLeftIcon from "@/components/shared/icons/ArrowNarrowLeftIcon";
import ArrowRight from "@/components/shared/icons/web/ArrowRight";
import CardIcon from "@/components/shared/icons/web/CardIcon";
import MenuIcon1 from "@/components/shared/icons/web/MenuIcon1";
import MenuIcon2 from "@/components/shared/icons/web/MenuIcon2";
import MenuIcon3 from "@/components/shared/icons/web/MenuIcon3";
import ChangePassWordIcon from "@/components/shared/icons/web/ChangePassWordIcon";
import LogoutIcon from "@/components/shared/icons/header/LogoutIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { logout, resetAll } from "../auth/auth.reducer";
import { CButton, CCol, CFormInput, CFormLabel, CRow } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import { getProfile } from "../auth/auth.api";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { ResponseStatusUser } from "@/shared/enumeration/ResponseStatus";

const Withdraw = () => {
    const { location, navigate } = useRouter();

    const { user } = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch<AppDispatch>();

    const [money, setMoney] = useState(0);
    const [noBank, setNoBank] = useState(false);


    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (Number.isNaN(Number(value))) return
        setMoney(Number(value));
    }

    useEffect(() => {
        if (!user?.cardName || !user?.cardNumber || !user?.bankName) {
            setNoBank(true)
        }
    }, [user?.cardName, user?.cardNumber, user?.bankName])

    const onSubmit = async () => {

        if (user?.status === ResponseStatusUser.CAMGIAODICH) {
            ToastError('Bạn bị cấm giao dịch. Vui lòng liên hệ CSKH để đặt cược');
            return;
        }

        if (money <= 0) {
            ToastError('Số tiền rút phải lớn hơn 0')
            return
        }

        if (money > (user?.money ?? 0)) {
            ToastError('Số tiền không hợp lệ')
            return
        }

        try {
            const response = await axios.post('/transaction', {
                money, type: 2
            });
            setMoney(0)
            ToastSuccess('Hệ thống đang xử lý. Vui lòng chờ trong ít phút')
            dispatch(getProfile());
        } catch (error) {
            console.error('Error:', error);
            dispatch(getProfile());
        }
    }


    if (noBank) {
        return <Navigate to="/add-bank" replace state={{ path: location.pathname }} />;
    }

    return (
        <Layout >
            <div className="box-withdraw">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Rút tiền</h5>
                </div>

                <div className="box-content mt-4">

                    <h5>Giới hạn rút: {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}</h5>

                    <div className="d-flex align-items-center form-withdraw gap-4">
                        <label>Số tiền rút</label>
                        <input type="text" onChange={(e) => onChange(e)} placeholder="Nhập số tiền rút" value={money} />
                    </div>

                    <div className="bank mt-5">
                        <div>Thông tin ngân hàng</div>
                        <div>{user?.bankName}</div>
                        <div>{user?.cardNumber}</div>
                        <div>{user?.cardName}</div>
                    </div>

                    <div className="px-3 mt-4">
                        <CButton className="w-100" onClick={onSubmit}>Xác nhận</CButton>
                    </div>

                </div>

            </div>
        </Layout>
    )
}

export default Withdraw;