import { NavLink } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import Nav from './Nav'
import { CButton, CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro'
import { useState } from 'react'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import axios from '../../../shared/config/axios-interceptor'

const MatKhauRutTien = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const submitHanlder = async () => {
    if (user?.passsWithdraw) {
      if (!currentPassword || !newPassword || !confirmNewPassword) {
        ToastError('Vui lòng nhập đầy đủ thông tin')
        return
      }

      if (currentPassword !== user?.passsWithdraw) {
        ToastError('Mật khẩu cũ sai')
        return
      }
    }

    if (!newPassword || !confirmNewPassword) {
      ToastError('Vui lòng nhập đầy đủ thông tin')
      return
    }

    if (newPassword !== confirmNewPassword) {
      ToastError('Xác nhận mật khẩu không khớp')
      return
    }

    try {
      const response = await axios.put('/user/' + user?.id, {
        passsWithdraw: newPassword,
      })
      ToastSuccess('Cập nhật mật khẩu rút tiền thành công')
      dispatch(getProfile())
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <div className="container-web page-rut-tien-web">
      <Header />
      <div className="content-header d-flex gap-3">
        <Nav />
        <div className="header-bottom m-0 w-100">
          <div
            className="top d-flex px-3  align-items-center fw-bold"
            style={{ height: 45 }}
          >
            Mật khẩu rút tiền
          </div>

          <div className="p-3 d-flex gap-3">
            <div style={{ width: 600 }}>
              {user?.passsWithdraw && (
                <CRow className="mt-3">
                  <CCol xs={3}>
                    <CFormLabel>Mật khẩu cũ</CFormLabel>
                  </CCol>
                  <CCol xs={9}>
                    <CFormInput
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </CCol>
                </CRow>
              )}

              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>Mật khẩu mới</CFormLabel>
                </CCol>
                <CCol xs={9}>
                  <CFormInput
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </CCol>
              </CRow>

              <CRow className="mt-3">
                <CCol xs={3}>
                  <CFormLabel>Xác nhận mật khẩu mới</CFormLabel>
                </CCol>
                <CCol xs={9}>
                  <CFormInput
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </CCol>
              </CRow>

              <div className="text-end mt-4 mb-3">
                <CButton
                  onClick={submitHanlder}
                  className="px-4"
                  style={{ background: '#f49400' }}
                >
                  Lưu lại
                </CButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body-web">
        <Sidebar />
      </div>
    </div>
  )
}
export default MatKhauRutTien
