export enum ResponseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  // DELETED = 'DELETED',
}

export const responseStatusArray: ResponseStatus[] = [
  ResponseStatus.ACTIVE,
  ResponseStatus.INACTIVE,
  // ResponseStatus.DELETED,
];

export const mapResponseStatus: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'Hoạt động',
  [ResponseStatus.INACTIVE]: 'Không hoạt động',
  // [ResponseStatus.DELETED]: 'Đã xóa',
};

export const mapResponseStatusToColor: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'success',
  [ResponseStatus.INACTIVE]: 'danger',
  // [ResponseStatus.DELETED]: 'gray',
};



export enum ResponseStatusUser {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CAMGIAODICH = 'CAMGIAODICH',
  // DELETED = 'DELETED',
}

export const responseStatusUserArray: ResponseStatusUser[] = [
  ResponseStatusUser.ACTIVE,
  ResponseStatusUser.INACTIVE,
  ResponseStatusUser.CAMGIAODICH,
  // ResponseStatus.DELETED,
];

export const mapResponseStatusUser: { [key in ResponseStatusUser]: string } = {
  [ResponseStatusUser.ACTIVE]: 'Bình thường',
  [ResponseStatusUser.INACTIVE]: 'Cấm đăng nhập',
  [ResponseStatusUser.CAMGIAODICH]: 'Cấm giao dịch',
  // [ResponseStatus.DELETED]: 'Đã xóa',
};

export const mapResponseStatusUserToColor: { [key in ResponseStatusUser]: string } = {
  [ResponseStatusUser.ACTIVE]: 'success',
  [ResponseStatusUser.INACTIVE]: 'danger',
  [ResponseStatusUser.CAMGIAODICH]: 'gray',
  // [ResponseStatus.DELETED]: 'gray',
};



export enum ResponseDesignate {
  THUA = "THUA",
  THANG = "THANG",
}

export const mapResponseDesignate: { [key in ResponseDesignate]: string } = {
  [ResponseDesignate.THANG]: 'Thắng',
  [ResponseDesignate.THUA]: 'Thua',
};


export enum ResponseVip {
  VIP1 = '1',
  VIP2 = '2',
  VIP3 = '3',
  VIP4 = '4',
  VIP5 = '5',
  VIP6 = '6',
}

export const mapResponseVip: { [key in ResponseVip]: string } = {
  [ResponseVip.VIP1]: 'VIP 1',
  [ResponseVip.VIP2]: 'VIP 2',
  [ResponseVip.VIP3]: 'VIP 3',
  [ResponseVip.VIP4]: 'VIP 4',
  [ResponseVip.VIP5]: 'VIP 5',
  [ResponseVip.VIP6]: 'VIP 6',
};

export const responseVipArray: ResponseVip[] = [
  ResponseVip.VIP1,
  ResponseVip.VIP2,
  ResponseVip.VIP3,
  ResponseVip.VIP4,
  ResponseVip.VIP5,
  ResponseVip.VIP6
];







export enum ResponseStatusDraw {
  open = 'open',
  draw = 'drawn',
  closed = 'closed',
}

export const mapResponseStatusToColorDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'success',
  [ResponseStatusDraw.draw]: 'gray',
  [ResponseStatusDraw.closed]: 'danger',
};

export const mapResponseStatusDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'Đã mở',
  [ResponseStatusDraw.draw]: 'Chưa mở',
  [ResponseStatusDraw.closed]: 'Đã đóng',
};

export enum ResponseIsWin {
  THANG = 1,
  THUA = 0
}

export const mapResponseStatusToColorIsWin: { [key in ResponseIsWin]: string } = {
  [ResponseIsWin.THANG]: 'success',
  [ResponseIsWin.THUA]: 'danger',
};

export const mapResponseIsWin: { [key in ResponseIsWin]: string } = {
  [ResponseIsWin.THANG]: 'Thắng',
  [ResponseIsWin.THUA]: 'Thua',
};


export enum ResponseStatusTransaction {
  pending = 0,
  success = 1,
  error = 2,
}

export const mapResponseStatusToColorTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.success]: 'success',
  [ResponseStatusTransaction.pending]: 'primary',
  [ResponseStatusTransaction.error]: 'danger',
};

export const mapResponseStatusTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.pending]: 'Đang chờ',
  [ResponseStatusTransaction.success]: 'Thành công',
  [ResponseStatusTransaction.error]: 'Thất bại',
};


// export enum typeTransaction {
//   deposit = 1, // Nạp tiền
//   withdraw = 2, // Rút tiền
// }

// export enum statusTransaction {
//   pendding = 0, // Đang chờ
//   success = 1, // Thành công
//   error = 2, // Thất bại
// }



export enum ResponseVotedHistory {
  TANGLEN = 1,
  GIAMBOT = 2,
  SUAMOCCHAU = 3,
  SUACOGAIHALAN = 4,
  SUANUTI = 5,
  SUAGOLD = 6,
  SUALADY = 7,
  SUAMILO = 8,
  SUAANFA = 9,
  SUADAUNANH = 10,
}

export const mapResponseColorVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'TH TRUEMILK',
  [ResponseVotedHistory.GIAMBOT]: 'VINAMIK',
  [ResponseVotedHistory.SUAMOCCHAU]: 'Sữa mộc châu',
  [ResponseVotedHistory.SUACOGAIHALAN]: 'Sữa cô gái hà lan',
  [ResponseVotedHistory.SUANUTI]: 'Sữa nutifood',
  [ResponseVotedHistory.SUAGOLD]: 'Sữa optimun gold',
  [ResponseVotedHistory.SUALADY]: 'Sữa dutch lady',
  [ResponseVotedHistory.SUAMILO]: 'Sữa milo',
  [ResponseVotedHistory.SUAANFA]: 'Sữa anfa gold',
  [ResponseVotedHistory.SUADAUNANH]: 'Sữa đậu nành',
};


// Bi đặt được
export enum BiDatCuocGroup {
  VODICH = 1,
  QUANQUAN = 2,
  AQUAN = 3,
  HANG4 = 4,
  HANG5 = 5,
  HANG6 = 6,
  HANG7 = 7,
  HANG8 = 8,
  HANG9 = 9,
  HANG10 = 10,
}

export const mapBiDatCuocGroup: { [key in BiDatCuocGroup]: string } = {
  [BiDatCuocGroup.VODICH]: 'Giải vô địch',
  [BiDatCuocGroup.QUANQUAN]: 'Giải quán quân',
  [BiDatCuocGroup.AQUAN]: 'Giải á quân',
  [BiDatCuocGroup.HANG4]: 'Hạng 4',
  [BiDatCuocGroup.HANG5]: 'Hạng 5',
  [BiDatCuocGroup.HANG6]: 'Hạng 6',
  [BiDatCuocGroup.HANG7]: 'Hạng 7',
  [BiDatCuocGroup.HANG8]: 'Hạng 8',
  [BiDatCuocGroup.HANG9]: 'Hạng 9',
  [BiDatCuocGroup.HANG10]: 'Hạng 10',

};
